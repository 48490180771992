const enum AtomKeys {
  LOGOUT_MODAL = 'LOGOUT',

  CREATE_ACTIVITY_MODAL = 'CREATE_ACTIVITY',
  UPDATE_ACTIVITY_MODAL = 'UPDATE_ACTIVITY',
  DELETE_ACTIVITY_MODAL = 'DELETE_ACTIVITY',

  UPDATE_USER_MODAL = 'UPDATE_USER',
  DELETE_USER_MODAL = 'DELETE_USER',

  CREATE_ACTIVITY_LOCATION_MODAL = 'CREATE_ACTIVITY_LOCATION',
  DELETE_ACTIVITY_LOCATION_MODAL = 'DELETE_ACTIVITY_LOCATION',

  ACTIVE_USER_ID = 'ACTIVE_USER_ID',
  ACTIVE_ACTIVITY_ID = 'ACTIVE_ACTIVITY_ID',
  ACTIVE_ACTIVITY_LOCATION_ID = 'ACTIVE_ACTIVITY_LOCATION_ID',
}

export default AtomKeys;
